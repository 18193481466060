<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-img
  max-height="auto"
  max-width="250"
  :src="clientData.remarketing_template"
></v-img>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5 ml-5"
          @click="$refs.remarketingtemplate.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block ">Remarketing-Template ersetzen</span>
        </v-btn>

        <input
          ref="remarketingtemplate"
          type="file"
          accept=".jpeg,.png,.jpg,"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Zurücksetzen
        </v-btn>
        <p class="text-sm mt-5 ml-5">
          Erlaubt: JPG, oder PNG, Ideale Größe: 1920*1080
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userData.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userData.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="clientData.name"
              dense
              outlined
              label="Company"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
            >
              Änderungen speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions, mapGetters } from "vuex";

export default {
  setup(props) {

    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
    computed: {
      ...mapGetters("main", ["userData","clientData"]),
  },
}
</script>
